<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Chính sách bảo mật</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/tongshiimportacion/components/img/5_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      text:`108 Tran Dinh Xu, Nguyen Cu Trinh Ward, Ho Chi MinhLvsoft Loan chuyên cung cấp cho bạn các dịch vụ tài chính nhanh,tận tình.Tại Lvsoft Loan, chúng tôi cam kết đặt việc bảo mật thông tin khách hàng lên hàng đầu, đúng quy định.
 
 Địa chỉ: 108 Tran Dinh Xu, Nguyen Cu Trinh Ward, Ho Chi Minh
 Email: office@lvsoftcompanylimited.com
 Giờ làm việc là 9:00 sáng đến 6:00 chiều.
 Giờ Thứ Hai đến Thứ Sáu và Thứ Bảy và Chủ Nhật 9:00 đến 18:00,
 Cổng thông tin: www.lvsoftcompanylimited.com
 Đường dây nóng dịch vụ khách hàng: +84 6846727831
  
 I.Về khoản vay:
 Các thông tin khi vay tại Lvsoft Loan:
 - Hạn mức vay: lên tới 26,000,000 VNĐ
 - Thời hạn cho vay: 93-365 ngày
 - Lãi suất APR: cao nhất 20%/năm
 - Phí khác: 0đ
 Ví dụ: 
 Nếu bạn vay 2,000,000₫, lãi suất là 18% và thời hạn vay là 120 ngày, vậy số tiền phải trả là 2,118,356đ ( cụ thể là: 2,000,000*18% /365 *120 + 2,000,000)
  
 II.Quyền bảo mật
 Để đánh giá hạn mức tín dụng của bạn và tạo điều kiện cho bạn vay vốn nhanh hơn, chúng tôi cần một số quyền truy cập vào điện thoại di động, tin nhắn và máy ảnh của bạn. Bạn có thể liên hệ với trung tâm dịch vụ khách hàng của chúng tôi bất cứ lúc nào để xóa thông tin cá nhân của bạn:
  
 Tất cả các giao dịch được bảo vệ bằng mã hóa SSL 128 bit. Dữ liệu sẽ được truyền và tải lên thông qua các kết nối an toàn;
  
 Vì các mục đích được mô tả trong tuyên bố về quyền riêng tư này, chúng tôi sẽ sử dụng dữ liệu cá nhân sau:
  
 Sự đồng ý rõ ràng của Chủ sở hữu đối với việc xử lý Dữ liệu Cá nhân sẽ được thu thập thông qua trang web của chúng tôi và/hoặc ứng dụng di động mà chúng tôi cung cấp cho bạn ("Ứng dụng") hoặc bằng bất kỳ phương tiện điện tử, điện thoại, video hoặc phương tiện nào khác. Các công nghệ khác, có thể tự động diễn ra cùng lúc khi bạn liên hệ với chúng; các mục đích trên là để có thể cung cấp cho bạn các dịch vụ và/hoặc sản phẩm do Lvsoft Loan cung cấp và tuân thủ các mục đích được chỉ định trong tuyên bố về quyền riêng tư này.
 Cách chúng tôi thu thập và sử dụng thông tin cá nhân của bạn:
 Tại Lvsoft Loan, chúng tôi thu thập thông tin về thiết bị di động bạn sử dụng và từ nhiều nguồn khác nhau để cung cấp cho bạn các dịch vụ tốt hơn. Thông tin chúng tôi thu thập bao gồm:
 ●Dữ liệu cá nhân: tên, địa chỉ email, số điện thoại và thông tin khác mà bạn cung cấp cho chúng tôi. Thông tin này được mã hóa và tải lên máy chủ.
 ●Thông tin tài chính: Chúng tôi cần biết thông tin ngân hàng của bạn hoặc các phương thức thanh toán liên quan khác để xử lý khoản vay của bạn. Thông tin này được mã hóa và tải lên máy chủ.
 ●Thông tin liên hệ khẩn cấp: Chúng tôi sử dụng thông tin liên hệ khẩn cấp của bạn cung cấp để xác minh danh tính, đánh giá hạn mức tín dụng của bạn và phân tích khả năng gian lận Để bảo mật cho bạn, Thông tin này được mã hóa và tải lên máy chủ.
 ●Ứng dụng đã cài đặt: Chúng tôi thu thập danh sách các ứng dụng đã cài đặt trên thiết bị của bạn để phát hiện các ứng dụng bất hợp pháp. Thông tin này được mã hóa và tải lên máy chủ.
 ●Quyền truy cập máy ảnh: Bạn ủy quyền cho chúng tôi truy cập máy ảnh của bạn hoặc chụp ảnh/quay video để xác minh danh tính của bạn và đẩy nhanh quá trình cho vay của bạn. Thông tin này được mã hóa và tải lên máy chủ.
 ●Vị trí
 Chúng tôi cần quyền truy cập vào thông tin vị trí của bạn để cung cấp dịch vụ được cá nhân hóa và cải thiện trải nghiệm của bạn. Bằng cách thu thập thông tin vị trí của bạn, chúng tôi có thể đánh giá và xử lý đơn xin vay của bạn chính xác hơn; đề xuất các sản phẩm và dịch vụ cụ thể cho khu vực của bạn, đồng thời cải thiện tính bảo mật và ngăn chặn gian lận; Xin lưu ý rằng chúng tôi chỉ thu thập thông tin vị trí khi bạn sử dụng Ứng dụng và giữ bí mật và an toàn cho dữ liệu này. Thông tin vị trí của bạn sẽ không được chia sẻ với bất kỳ bên thứ ba nào trừ khi có sự đồng ý rõ ràng của bạn hoặc theo yêu cầu của pháp luật.
 ●Nhận diện khuôn mặt
 Chúng tôi cần có được quyền nhận dạng khuôn mặt của bạn để đảm bảo tính bảo mật cho tài khoản của bạn và cải thiện sự tiện lợi của việc đăng ký khoản vay. Bằng cách sử dụng công nghệ nhận dạng khuôn mặt, chúng tôi có thể xác minh danh tính của bạn và ngăn chặn truy cập trái phép; đảm bảo tính xác thực của thông tin bạn gửi trong quá trình đăng ký khoản vay, cải thiện tính bảo mật giao dịch và ngăn chặn hành vi trộm cắp danh tính và gian lận;
 ●Hình chụp
 Chúng tôi yêu cầu quyền truy cập vào album ảnh của bạn để tải lên và xác minh các tài liệu và thông tin cần thiết trong quá trình đăng ký khoản vay. Bằng cách có được quyền truy cập vào album ảnh, chúng tôi có thể tạo điều kiện cho bạn tải lên các tài liệu cần thiết như tài liệu nhận dạng và giấy chứng nhận thu nhập; đảm bảo tính xác thực và đầy đủ của các tài liệu đã gửi giúp đẩy nhanh quá trình phê duyệt khoản vay và cải thiện trải nghiệm người dùng của bạn;
 ●Danh bạ
 Chúng tôi yêu cầu quyền truy cập vào sổ địa chỉ của bạn để hợp lý hóa quy trình đăng ký khoản vay và đánh giá mức độ tín nhiệm của bạn. Bằng cách lấy thông tin sổ địa chỉ, chúng tôi có thể tự động điền thông tin liên hệ của bạn để nâng cao hiệu quả hoạt động; phân tích các mối quan hệ trên mạng xã hội của bạn và tiến hành đánh giá rủi ro tín dụng để xác nhận thông tin liên hệ khẩn cấp để cải thiện tính xác thực và bảo mật của các đơn xin vay tiền;
 Hãy yên tâm rằng chúng tôi sẽ bảo vệ nghiêm ngặt dữ liệu sổ địa chỉ của bạn và đảm bảo rằng dữ liệu đó chỉ được sử dụng cho các mục đích trên. Tất cả thông tin được thu thập sẽ được truyền và lưu trữ theo cách mã hóa và sẽ không được chia sẻ với bất kỳ bên thứ ba trái phép nào trừ khi có sự đồng ý rõ ràng của bạn hoặc theo yêu cầu của pháp luật.
 Chúng tôi sử dụng thông tin này cho nhiều mục đích khác nhau, bao gồm:
 ●Xác minh danh tính và xác minh thông tin của bạn.
 ●Xử lý đơn xin vay tiền và đánh giá hạn mức tín dụng của bạn.
 ●Phát hiện và ngăn chặn gian lận, rủi ro.
 ●Cải thiện dịch vụ của chúng tôi và phát triển sản phẩm mới.
 ●Cá nhân hóa trải nghiệm của bạn với Lvsoft Loan.
 ●Tuân thủ luật pháp và quy định hiện hành.
 Chúng tôi cam kết bảo vệ quyền riêng tư của bạn và sử dụng thông tin của bạn một cách có trách nhiệm. Để biết thêm thông tin chi tiết vui lòng xem chính sách riêng của chúng tôi.
 Lvsoft Loan thông báo cho Chủ sở hữu rằng anh ta phải thông báo cho chủ sở hữu tương ứng về dữ liệu cá nhân do Chủ sở hữu cung cấp cho Lvsoft Loan, việc xử lý dữ liệu cá nhân của họ và nội dung của điều khoản về quyền riêng tư này. 
  
 III. Chuyển dữ liệu cá nhân
 Chúng tôi thông báo cho bạn rằng dữ liệu cá nhân của bạn sẽ được chia sẻ với những người, công ty, tổ chức hoặc tổ chức sau đây bên ngoài chúng tôi trong và ngoài nước vì các mục đích sau:
 1. Hoạt động theo các quy trình và chính sách nội bộ giống như các công ty mẹ, công ty con hoặc chi nhánh dưới sự kiểm soát chung của chúng tôi và công ty mẹ hoặc bất kỳ công ty nào trong nhóm các công ty của chúng tôi để tạo cơ sở dữ liệu khách hàng và theo dõi các giao dịch.
 2. Liên hệ với cơ quan có thẩm quyền khi cần thiết theo quy định của pháp luật hiện hành.
 3. Khi cần thu phí ngoài tư pháp hoặc tư pháp, hãy cung cấp thông tin đó cho công ty hoặc các công ty chịu trách nhiệm thu nợ.
 4. Khi công ty tiến hành mua lại, sáp nhập hoặc các hoạt động kinh doanh tương tự khác với các công ty khác,
 5. Đàm phán với các bên thứ ba, bao gồm các tổ chức tài chính, việc chuyển nhượng hoặc chiết khấu danh mục tín dụng, bảo lãnh hoặc bất kỳ hình thức danh mục tín dụng nào khác nếu cần thu hồi thông qua
 các biện pháp ngoài tư pháp hoặc tư pháp.
 6. Ký kết hợp đồng với các đối tác kinh doanh để tiếp thị sản phẩm và/hoặc dịch vụ vì lợi ích của Chủ sở hữu, nhằm mục đích cung cấp cho khách hàng của chúng tôi hàng hóa hoặc dịch vụ mà họ có thể quan tâm.
 7. Tiến hành phân tích dữ liệu với các đối tác bên ngoài để xác minh tính khả thi của việc cấp tín dụng.
 8. Làm việc với các nhà cung cấp nguồn dữ liệu bên thứ ba để cung cấp cho khách hàng của chúng tôi hàng hóa hoặc dịch vụ mà họ có thể quan tâm.
  
 IV. Quyền thực hiện
 Bạn có thể thực hiện bất kỳ quyền truy cập, cải chính, hủy bỏ hoặc phản đối nào (thường được gọi là "quyền ARCO") và rút lại sự đồng ý của bạn đối với việc xử lý thông tin cá nhân của bạn, trừ khi pháp luật yêu cầu lưu trữ dữ liệu nói trên. Việc thực hiện các quyền này phải bằng văn bản và chuyển đến khu vực dịch vụ khách hàng, nơi sẽ chịu trách nhiệm xử lý các yêu cầu dữ liệu cá nhân và thúc đẩy việc bảo vệ dữ liệu cá nhân trong Lvsoft Loan; hoặc qua email đến địa chỉ sau: contactoffice@lvsoftcompanylimited.com
 Yêu cầu trên phải có các thông tin và tài liệu sau:
 • Tên và địa chỉ hoặc các phương tiện khác để phản hồi yêu cầu của bạn, cùng với tài liệu chứng minh danh tính của bạn hoặc đại diện pháp lý, nếu có.
 • Bất kỳ yếu tố hoặc tài liệu nào khác hỗ trợ việc định vị dữ liệu cá nhân.
 • Yêu cầu phải được ký ở cuối tài liệu và cuối mỗi trang.
 • Mô tả rõ ràng và chính xác về dữ liệu cá nhân mà bạn muốn thực hiện các quyền ARCO của mình cũng như quyền hoặc các quyền mà bạn muốn thực hiện, trong nội dung email hoặc dưới dạng bản sao được quét và tệp đính kèm. Ký chính thức vào cuối văn bản và ký tên ở cuối mỗi trang.
 Sau khi nộp đơn đăng ký theo các điều khoản trên, Lvsoft Loan sẽ đưa ra quyết định được phê duyệt trong vòng 2 (hai) ngày kể từ ngày nhận được đơn đăng ký.
 Nếu yêu cầu phù hợp, yêu cầu đó sẽ có hiệu lực chậm nhất là 10 (mười) ngày làm việc kể từ ngày bên liên quan thông báo phản hồi. Nếu bạn cung cấp thông tin không chính xác hoặc không đầy đủ trong yêu cầu của mình hoặc không cung cấp các tài liệu cần thiết chứng minh danh tính hoặc đại diện pháp lý phù hợp của bạn thì bên liên quan sẽ yêu cầu bạn trong vòng 5 (năm) ngày làm việc kể từ khi nhận được yêu cầu của bạn. Nó. Trong những trường hợp này, bạn sẽ có 10 (mười) ngày làm việc để phản hồi yêu cầu chỉnh sửa, bắt đầu từ ngày nhận được yêu cầu. Nếu bạn không phản hồi trong khoảng thời gian quy định, điều đó sẽ được coi là yêu cầu tương ứng chưa được gửi.
 Chủ sở hữu sẽ chịu trách nhiệm cập nhật dữ liệu cá nhân thuộc quyền sở hữu của người chịu trách nhiệm; do đó, trong mọi trường hợp, Chủ sở hữu đảm bảo và chịu trách nhiệm về tính xác thực, chính xác, hợp lệ và xác thực của dữ liệu cá nhân được cung cấp và cam kết cập nhật dữ liệu đó kịp thời và truyền đạt mọi thay đổi tới bộ điều khiển.
 Nếu yêu cầu thực hiện quyền ARCO liên quan đến việc thực hiện quyền truy cập, Lvsoft Loan sẽ cung cấp cho bạn thông tin hoặc dữ liệu cá nhân bằng bản sao đơn giản và/hoặc tài liệu điện tử tại địa chỉ điện tử do Chủ sở hữu chỉ định cho mục đích này. Chủ sở hữu dữ liệu cá nhân chỉ có thể rút lại sự đồng ý xử lý dữ liệu cá nhân theo các thủ tục được mô tả ở trên và trong phạm vi được luật áp dụng cho bên kiểm soát cho phép.
 Lvsoft Loan có thể từ chối thực hiện các quyền ARCO trong chính mình.`,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  /* padding-top: 40px; */
  padding-bottom: 20px;
  height: 3700px;
  background-color: "#FFFFFF";
  border-radius: 32px;
  margin: auto;
  margin-top: -50px;
  transform: scale(87%,95%);
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
 background-image: url(./components/img/Rectangle1.png);
 background-position:0 40px;
 background-repeat: no-repeat;
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>